function millionDollarCashPool(width) {
  switch (true) {
    case width > 1500:
      return 'long'
    case width <= 1500 && width > 1280:
      return 'mid-long'
    case width < 600:
      return 'xShort'
    case width < 870:
      return 'short'
    case width < 1000:
      return 'mid-short'
    default:
      return 'mid'
  }
}

function rugby(width) {
  switch (true) {
    case width > 2047:
      return 'long'
    case width <= 2047 && width > 1400:
      return 'mid-long'
    case width < 580:
      return 'xxx-short'
    case width < 735:
      return 'xx-short'
    case width < 810:
      return 'x-short'
    case width < 955:
      return 'short'
    case width < 1200:
      return 'mid-short'
    default:
      return 'mid'
  }
}

function firstTimeDeposit(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width <= 1660 && width > 1400:
      return 'mid-long'
    case width < 735:
      return 'xx-short'
    case width < 810:
      return 'x-short'
    case width < 955:
      return 'short'
    case width < 1100:
      return 'mid-short'
    default:
      return 'mid'
  }
}

function depositBonus(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width > 1400:
      return 'mid-long'
    case width > 1200:
      return 'mid-short'
    case width > 768:
      return 'xx-short'
    case width > 576:
      return 'xxx-short'
    default:
      return 'mid'
  }
}

function firstDepositCashback(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width > 1400:
      return 'mid-long'
    case width > 1200:
      return 'mid-short'
    case width > 768:
      return 'xx-short'
    case width > 576:
      return 'xxx-short'
    default:
      return 'mid'
  }
}

function referFriend(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width > 1400:
      return 'mid-long'
    case width > 1200:
      return 'mid-short'
    case width > 992:
      return 'short'
    case width > 800:
      return 'x-short'
    case width > 700:
      return 'xx-short'
    case width > 650:
      return 'xxx-short'
    case width > 576:
      return 'xxxx-short'
    default:
      return 'mid'
  }
}

function propTrade(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width > 1400:
      return 'mid-long'
    case width > 1200:
      return 'mid-short'
    case width > 768:
      return 'xx-short'
    case width > 576:
      return 'xxx-short'
    default:
      return 'mid'
  }
}

function xmasSpinAndWin(width) {
  switch (true) {
    case width > 2047:
      return 'long'
    case width <= 2047 && width > 1400:
      return 'mid-long'
    case width < 580:
      return 'xxx-short'
    case width < 735:
      return 'xx-short'
    case width < 810:
      return 'x-short'
    case width < 955:
      return 'short'
    case width < 1200:
      return 'mid-short'
    default:
      return 'mid'
  }
}

function nfp(width) {
  switch (true) {
    case width > 1550:
      return 'long'
    case width <= 1550 && width > 1280:
      return 'mid-long'
    case width < 600:
      return 'x-short'
    case width < 996:
      return 'short'
    case width < 1200:
      return 'mid-short'
    default:
      return 'mid'
  }
}

function vdayPromo(width) {
  switch (true) {
    case width > 1550:
      return 'long'
    case width <= 1550 && width > 1280:
      return 'mid-long'
    case width < 600:
      return 'x-short'
    case width < 996:
      return 'short'
    case width < 1200:
      return 'mid-short'
    default:
      return 'mid'
  }
}

function euro2024(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width < 600:
      return 'short'
    default:
      return 'mid'
  }
}

function olympic2024(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width < 600:
      return 'short'
    default:
      return 'mid'
  }
}

export {
  millionDollarCashPool,
  firstTimeDeposit,
  depositBonus,
  firstDepositCashback,
  referFriend,
  rugby,
  xmasSpinAndWin,
  nfp,
  propTrade,
  vdayPromo,
  euro2024,
  olympic2024,
}
